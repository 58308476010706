.modalPaper {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 412px;
  padding: 34px 38px 38px;
  overflow-wrap: break-word;

  .imageBox {
    height: 93px;

    img {
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  .modalText {
    display: flex;
    flex-direction: column;
    gap: 8px;
    text-align: center;

    .modalTitle {
      font: var(--title-22-black);
    }

    p {
      font: var(--title-16-regular);
      color: var(--natural-700);
    }
  }

  .buttonBox {
    display: grid;
    grid-auto-columns: 1fr;
    grid-auto-flow: column;
    column-gap: 16px;
  }

  @media (width <= 450px) {
    width: 100%;
    padding: 20px;
    margin: 16px;
  }
}
