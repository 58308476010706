.bioChipBox {
  display: flex;
  gap: 4px;
  align-items: center;
}

.bioDescription {
  display: flex;
  gap: 16px;
  align-items: start;
  justify-content: space-between;
  font: var(--body-14-medium);
}
