.tipsList {
  margin-top: 4px;
  font: var(--body-14-regular);
  color: var(--main-black);

  li {
    margin-bottom: 4px;
    margin-left: 24px;
    list-style: disc;
  }
}

.description {
  font: var(--body-14-regular);
  font-style: italic;
  color: var(--main-black);
}
