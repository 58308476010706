.box {
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 20px 12px;
  align-items: center;
  margin-bottom: 8px;
}

.row {
  display: flex;
  grid-column: 2 / -1;
  gap: 12px;
  align-items: center;
  justify-content: space-between;
}

.inbodyLogo {
  display: block;
  width: 48px;
  height: 48px;
  padding: 0 2px 0 4px;
  border: 1px solid var(--natural-300);
  border-radius: 8px;
  object-fit: contain;
}

.idTitleBox {
  display: flex;
  gap: 8px;
  align-items: center;
}

.hookName {
  color: var(--main-black);
}

.divider {
  grid-column: 1 / -1;
}
