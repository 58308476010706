.rows {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
}

.extraInfo {
  margin-bottom: 16px;
}
