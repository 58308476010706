$mobile-width: 700px;

.container {
  // because of whiteBackground
  position: relative;
  z-index: 2;
  display: grid;
  flex-grow: 1;
  grid-template-areas:
    'titleBlock h20bScaleBlock'
    'divider h20bScaleBlock'
    'videoBlock h20bScaleBlock';
  grid-template-columns: minmax(0, 430px) minmax(0, 412px);
  gap: 32px;
  justify-content: space-between;
  max-width: 1046px;
  padding: 32px 40px;

  @media (width <= $mobile-width) {
    grid-template-areas:
      'titleBlock '
      'h20bScaleBlock'
      'videoBlock';
    grid-template-columns: 1fr;
    gap: 40px;
    padding: 32px 20px;
  }
}

.titleBlock {
  display: flex;
  flex-direction: column;
  grid-area: titleBlock;
  gap: 24px;
}

.videoBlock {
  grid-area: videoBlock;
}

.divider {
  grid-area: divider;

  @media (width <= $mobile-width) {
    display: none;
  }
}

.h20bScaleBlock {
  grid-area: h20bScaleBlock;
  align-self: center;
}

.whiteBackground {
  position: fixed;
  inset: 0;
  z-index: 1;
  background-color: var(--white);
}
