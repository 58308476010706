.container {
  padding: 32px;
  border: 1px solid var(--natural-300);
  border-radius: 8px;
}

.titleBlock {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: center;
  margin-bottom: 24px;

  .chip {
    padding: 4px 8px;
    font: var(--body-14-bold);
    color: var(--white);
    background-color: var(--error-500);
    border-radius: 12px;
  }

  .title {
    font: var(--title-22-black);
  }
}

.scaleImage {
  display: block;
  margin: 0 auto 24px;
}

.infoBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
  align-items: start;
  margin-bottom: 16px;

  h4 {
    font: var(--body-14-bold);
  }

  p {
    font: var(--body-14-regular);
  }
}

.priceBox {
  display: inline-flex;
  flex-direction: column;
  gap: 2px;
  margin-bottom: 32px;

  .initialPrice {
    font: var(--body-14-regular);
    color: var(--natural-600);
    text-decoration: line-through;
  }

  .discountPrice {
    font: var(--title-18-bold);
  }
}
