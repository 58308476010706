.row {
  display: grid;
  grid-template-columns: auto auto 1fr;
  gap: 8px;
  align-items: center;
  padding: 12px;
  margin-bottom: 8px;
  cursor: pointer;
  border: 1px solid var(--white);

  .bio {
    grid-column: 2 / span 2;
    font: var(--body-14-regular);
    font-style: italic;
    color: var(--natural-600);
  }
}

.checkedRow {
  border: 1px solid var(--primary-500);
}

@media (hover) {
  .row:hover {
    border: 1px solid var(--primary-500);
  }
}
