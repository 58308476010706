// need find some better solution to add wight only for last child if do not know number of middle child

.customerTable {
  display: grid;
  grid-template-columns: 300px 1fr auto;

  :global(.tableGridLayout:not(.tableGridLayout .tableGridLayout)) {
    grid-template-columns: subgrid;
    grid-column: 1 /-1;
    gap: 24px;
    place-items: center start;

    :global(.tableGridLayout) {
      grid-auto-columns: 1fr;
      gap: inherit;
      align-items: inherit;
      justify-items: inherit;
      font: inherit;
    }
  }
}
