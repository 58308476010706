.container {
  display: flex;
  gap: 16px;
  align-items: center;
  padding: 20px 24px;
  margin-bottom: 16px;
}

.infoBox {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.name {
  font: var(--title-28-bold);
}

.descriptionBox {
  display: flex;
  gap: 12px;
  align-items: center;
  font: var(--body-14-medium);
  color: var(--main-black);

  .label {
    color: var(--natural-700);
  }
}
