.avatarBox {
  position: relative;
}

.avatarUpload {
  position: absolute;
  inset: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0; // fix bootstrap margin
  font: var(--body-12-bold);
  color: var(--white);
  cursor: pointer;
  background-color: color-mix(in srgb, var(--main-black) 40%, transparent);
  border-radius: 50%;
  opacity: 0;
  transition: opacity 0.2s;

  &:hover {
    opacity: 1;
  }

  input {
    display: none;
  }
}
