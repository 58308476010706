.img {
  display: block;
  margin: 0 auto 24px;
}

.btnBox {
  display: grid;
  grid-template-columns: 1fr;
  gap: 12px;
}

.infoBox {
  margin-bottom: 16px;

  h4 {
    margin-bottom: 8px;
    font: var(--body-14-bold);
  }

  p {
    margin-bottom: 8px;
    font: var(--body-14-regular);
  }
}

.priceBox {
  display: inline-flex;
  flex-direction: column;
  gap: 2px;

  .initialPrice {
    font: var(--body-14-regular);
    color: var(--natural-600);
    text-decoration: line-through;
  }

  .discountPrice {
    font: var(--title-18-bold);
  }
}
