.topBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 24px;
  margin-bottom: 8px;

  .leftItems {
    display: flex;
    gap: 16px;
    align-items: center;

    .input {
      width: 195px;
    }
  }

  .rightItems {
    display: inline-flex;
    gap: 16px;
    align-items: center;

    @media print {
      display: none;
    }
  }

  .tabsList {
    gap: 4px;
    margin-bottom: 0;

    button {
      gap: 0;
      height: auto;
      padding: 8px;
      line-height: 0;
    }
  }
}
