.checkInHistoryTable {
  :global(.tableGridLayout) {
    grid-template-columns: subgrid;
    grid-column: 1 /-1;
    gap: 24px;
    place-items: center start;
  }
}

.header {
  align-items: center;
  padding-top: 7px;
  padding-bottom: 7px;

  .titleCell {
    align-self: stretch; // when there is no measurement, the cell should stretch to the height of the row
  }
}
